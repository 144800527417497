import ApplicationController from "./application_controller"
import videojs from 'video.js'
import store from 'store2';
import 'video.js/dist/video-js.css'

import '../lib/videojs/max_progress_bar/plugin'

import throttle from 'underscore/modules/throttle'

import consumer from '../channels/consumer'

const PERSIST_WINDOW = 10000 // In microseconds

export default class extends ApplicationController {
    static targets = ['player', 'next']
    static values = {
        'attempt': String,
        'max-time': Number,
    }

    connect() {
        super.connect()
    }

    initialize() {
        if (!this.playerTarget) {
            return
        }

        // https://stimulus.hotwire.dev/reference/targets
        let video = videojs(this.playerTarget, {
            controls: true,
            playbackRates: [0.5, 1, 2],
            autoplay: false,
            // responsive: true,
            fluid: true,
            // fill: true,
        })

        let next = this.nextTarget

        let channel = consumer.subscriptions.create({
            channel: 'LessonAttemptChannel',
            id: this.attemptValue,
        })

        const suffix = this.attemptValue
        const CURRENT_TIME = "current-time-" + suffix
        const MAX_TIME = "max-time-" + suffix
        const storage = store.session

        video.addClass('video-js')
        video.addClass('vjs-big-play-centered')
        if(this.maxTimeValue) {
            storage.set(MAX_TIME, this.maxTimeValue)
        }
        let max = video.maxProgress({max: storage.get(MAX_TIME)})

        let persist = throttle(() => {
            // console.log("Persist", storage.get(MAX_TIME))
            channel.perform("persist", {
                "max-time": storage.get(MAX_TIME),
            })
        }, PERSIST_WINDOW)

        video.ready(function () {
            if (!storage.has(CURRENT_TIME) && storage.has(MAX_TIME)) {
                storage.set(CURRENT_TIME, storage.get(MAX_TIME));
            }
            // Resume playback from stopped location.
            if (storage.has(CURRENT_TIME)) {
                //resumeFromTime -= 2; // Offset
                let resume = storage.get(CURRENT_TIME);
                if (resume <= 0) {
                    resume = 0;
                }
                this.currentTime(resume)
            }
            // Ensure that current time will be saved for future.
            video.on('ended', function () {
                storage.remove(CURRENT_TIME);
                channel.perform("finish");
                next.classList.remove('disabled');
                next.scrollIntoView();
            });
            video.on('timeupdate', function () {
                if (!video.seeking() && !video.scrubbing()) {
                    storage.set(CURRENT_TIME, this.currentTime());
                    storage.set(MAX_TIME, max.bar.getMaxTime())
                }
                persist()
            });
        });
    }

    disconnect() {
    }
}