import videojs from 'video.js'

import './plugin.scss'
import { MaxProgressBar } from './component/max_progress_bar'

const Plugin = videojs.getPlugin('plugin');

class MaxProgress extends Plugin {
    constructor(player, options) {
        super(player);

        const parent = player.getChild('ControlBar').getChild('ProgressControl').getChild('SeekBar');
        this.bar = parent.addChild('MaxProgressBar', options, 1);
    }
}

// Register the plugin with video.js.
videojs.registerPlugin('maxProgress', MaxProgress);
videojs.registerComponent('MaxProgressBar', MaxProgressBar);

export default MaxProgress;